import {
    Button,
    Grid,
    LinearProgress,
    TextField,
    Typography,
} from '@mui/material'
import { FormSection } from '../../../../UniversalComponents'
import { useAuth } from '../../../../../context/AuthenticationContext'
import UniversalImageDisplayer from '../../../../UniversalComponents/UniversalImageDisplay'
import UniversalDataTable from '../../../../UniversalComponents/UniversalDataTable'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const EngineInsights = ({ openEdit, row }) => {
    const authentication = useAuth()
    const { auth } = authentication
    const { activeManufacturer, userRole } = auth
    const {
        manufacturerName,
        engineIdentifier,
        activeEngineCalibrationFile,
        engineName,
        engineImageUrl,
        manufacturer,
    } = row

    const manufacturerCheck = activeManufacturer === manufacturerName

    return (
        <Grid container spacing={2} sx={{ marginTop: 4 }}>
            <Grid item xs={12} md={12}>
                {manufacturerCheck && (
                    <FormSection>
                        <Grid item xs={12}>
                            <Button
                                onClick={openEdit}
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    height: 55,
                                }}
                            >
                                {`Edit ${engineName}`}
                            </Button>
                        </Grid>
                    </FormSection>
                )}
                <Grid item xs={12} md={12}>
                    <FormSection
                        title={`${engineName} Calibration File Details`}
                    >
                        {activeEngineCalibrationFile !== undefined ? (
                            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={'Current Active Calibration'}
                                        value={
                                            activeEngineCalibrationFile?.engineCalibrationFileName ||
                                            'No Active Calibration Found'
                                        }
                                        disabled={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={'Calibration Checksum'}
                                        value={
                                            activeEngineCalibrationFile?.engineCalibrationChecksum ||
                                            ''
                                        }
                                        disabled={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4" align="center">
                                    <ErrorOutlineIcon
                                        fontSize="medium"
                                        color="primary"
                                    />{' '}
                                    No Active Calibration File Detected for{' '}
                                    {engineName}
                                </Typography>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ marginTop: 5 }}
                                >
                                    <LinearProgress
                                        variant="indeterminate"
                                        sx={{
                                            '& .MuiLinearProgress-bar': {
                                                animationDuration: '5s',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormSection>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <FormSection title={'Engine'}>
                                    <UniversalImageDisplayer
                                        url={
                                            engineImageUrl
                                                ? engineImageUrl
                                                : 'https://pcmengines.com/wp-content/uploads/2023/12/ZZ8S-Transparent-1000x1000-1.png'
                                        }
                                        subtitle={`${engineName}`}
                                        title={
                                            manufacturer?.manufacturerName ||
                                            row?.manufacturer
                                        }
                                        variant="h4"
                                        width={'55%'}
                                        height={'55%'}
                                    />
                                </FormSection>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <FormSection title={'Release Notes'}>
                                    {/* <UniversalDataTable
                                    data={''}
                                    visibleColumns={[]}
                                /> */}
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default EngineInsights
